const timeout = 30000;
const configs = {
    'development': {
        'baseUrl': 'https://test-contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'production': {
        'baseUrl': 'https://test-contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    "bnbmeindia": {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'staging': {
        //"baseUrl": "http://localhost:1338/api/v2/",
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'demo': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        //"baseUrl": "http://localhost:1338/api/v2/",
        'timeout': timeout,
    },
    'escape2thesands': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'youarehome': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'ldg': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'clarendon': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'signetapartments': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'charmstay': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'cosmopolitanapartments': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'citystayapartment': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'roomspaceuat': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'roomspace': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'harrogateapartments': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'tempstay': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'kepplestonemanor': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'scandik': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'bnbmesaudi': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'bnbme': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'multicurrencytest2': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'multicurrencytest': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'flyingbutler': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'sixstays': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'zomelle': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'buckinghamandlloyds': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'domusstay': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'qapartments': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'markeygroup': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'mabaat': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'week2week': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'thecollectivelive': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        //'baseUrl': 'http://localhost:1338/api/v2/',
        'timeout': timeout,
    },
    'thecollective': {
        'baseUrl': 'https://test-contact-api.rerumapp.uk/api/v2/',
        //'baseUrl': 'http://localhost:1338/api/v2/',
        'timeout': timeout,
    },
    'khhomes': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'basilstreetapartments': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'homa': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'stayo': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'tailoredstays': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    'swisslodginggmbh': {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    "homenhancement": {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
	},
	"aqora": {
		'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
		'timeout': timeout,
    },
	"helmswood": {
		'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
		'timeout': timeout,
    },
	"livingtown": {
		'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
		'timeout': timeout,
    },
	"beyondapartments": {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    "briscoeandshaw": {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    "ariv": {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    "shortstaycrawley": {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    "gravitycoliving": {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    "cotels": {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    "heliodoorapartments": {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    "bbf": {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    "360apartments": {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    "mgroupservicedaccommodation": {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    "propertybycapital": {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    "swedenlongstay": {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    "training": {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    "mialiving": {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    "homex": {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    "alohause": {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    "smarterrent": {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    "alohausees": {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    "diffrent": {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    "canarywharf": {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    "vitanovae": {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    "skyside": {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    "southallandsoul": {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
    "shoreditchandsoul": {
        'baseUrl': 'https://contact-api.rerumapp.uk/api/v2/',
        'timeout': timeout,
    },
};

export default function(env) {
	if (configs[env] !== undefined) {
		return configs[env];
	}
	return configs.production;
}
