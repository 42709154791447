export default function() {
	const currentURL = window.location.host;
	if (currentURL.includes("localhost:3000")) {
		return "shoreditchandsoul";
	} else if (currentURL.includes("escape")) {
		return "escape2thesands";
	} else if (currentURL.includes("mcdemo")) {
		return "mcdemo";
	} else if (currentURL.includes("demo")) {
		return "demo";
	} else if (currentURL.includes("signet")) {
		return "signetapartments";
	} else if (currentURL.includes("cosmo")) {
		return "cosmopolitanapartments";
	} else if (currentURL.includes("citystay")) {
		return "citystayapartment";
	} else if (currentURL.includes("roomspaceuat")) {
		return "roomspaceuat";
	} else if (currentURL.includes("roomspace")) {
		return "roomspace";
	} else if (currentURL.includes("kepplestone")) {
		return "kepplestonemanor";
	} else if (currentURL.includes("scandik")) {
		return "scandik";
	} else if (currentURL.includes("bnbmesaudi")) {
		return "bnbmesaudi";
	} else if (currentURL.includes("bnbme")) {
		return "bnbme";
	} else if (currentURL.includes("multicurrencytest3")) {
		return "multicurrencytest3";
	} else if (currentURL.includes("multicurrencytest2")) {
		return "multicurrencytest2";
	} else if (currentURL.includes("flyingbutler")) {
		return "flyingbutler";
	} else if (currentURL.includes("buckinghamandlloyds")) {
		return "buckinghamandlloyds";
	} else if (currentURL.includes("domusstay")) {
		return "domusstay";
	} else if (currentURL.includes("qapartments")) {
		return "qapartments";
	} else if (currentURL.includes("markeygroup")) {
		return "markeygroup";
	} else if (currentURL.includes("mabaat")) {
		return "mabaat";
	} else if (currentURL.includes("week2week")) {
		return "week2week";
	} else if (currentURL.includes("homenhancement")) {
		return "homenhancement";
	} else if (currentURL.includes("basilstreetapartments")) {
		return "basilstreetapartments";
	} else if (currentURL.includes("tailoredstays")) {
		return "tailoredstays";
	} else if (currentURL.includes("swisslodginggmbh")) {
		return "swisslodginggmbh";
    } else if (currentURL.includes("aqora")) {
		return "aqora";
    } else if (currentURL.includes("livingtown")) {
		return "livingtown";
    } else if (currentURL.includes("beyondapartments")) {
		return "beyondapartments";
	} else if (currentURL.includes("briscoeandshaw")) {
		return "briscoeandshaw";
	} else if (currentURL.includes("ariv")) {
		return "ariv";
	} else if (currentURL.includes("gravitycoliving")) {
		return "gravitycoliving";
	} else if (currentURL.includes("cotels")) {
		return "cotels";
	} else if (currentURL.includes("bbf")) {
		return "bbf";
	} else if (currentURL.includes("360apartments")) {
		return "360apartments";
	} else if (currentURL === "live.thecollective.com" || currentURL === "guestportal-thecollectivelive.rerumapp.uk") {
		return "thecollectivelive";
	} else if (currentURL === "guestportal-thecollective.rerumapp.uk") {
		return "thecollective";
	} else if (currentURL.includes("propertybycapital")) {
		return "propertybycapital";
	} else if (currentURL.includes("swedenlongstay")) {
		return "swedenlongstay";
	}else if (currentURL.includes("homex")) {
		return "homex";
	} else if (currentURL.includes("mialiving")) {
		return "mialiving";
	}else if (currentURL.includes("alohause")) {
		return "alohause";
	}else if (currentURL.includes("smarterrent")) {
		return "smarterrent";
	}else if (currentURL.includes("alohausees")) {
		return "alohausees";
	}else if (currentURL.includes("canarywharf")) {
		return "canarywharf";
	}else if (currentURL.includes("vitanovae")) {
		return "vitanovae";
  }else if (currentURL.includes("diffrent")) {
			return "diffrent";
	}else if (currentURL.includes("skyside")) {
			return "skyside";
	}else if (currentURL.includes("southallandsoul")) {
			return "southallandsoul"
	}else if (currentURL.includes("shoreditchandsoul")) {
			return "shoreditchandsoul"
  } else {
		return "default";
	}
}
